<template>
    <div>
        <div v-if="$root.isAdmin() || $root.isServerAdmin()" class="content">
            <div class="c">
                <div class="c-info-block">
                    <h1 class="c-title">Панель администратора</h1>
                    <p class="c-info-text">
                        Добро пожаловать администратор <span
                        class="pulseNickName">{{ $root.getPlayerData().name }}!</span><br>

                        Когда вы перестанете копаться в том, что уже произошло и тревожиться о том, что еще не
                        случилось, тогда вы сможете присутствовать в настоящем моменте и испытаете радость жизни.
                        Любая ошибка в прошлом простительна, если в настоящем вы делаете всё, чтобы её не повторять.
                        <br><br>
                        Администратор - не забывай об ответственности, ты двигаешь этот проект к новым высотам!
                    </p>
                </div>

                <div class="c-row-actions-container">
                    <div class="c-row-actions">
                        <ul style="font-size: 0.85em; flex-wrap: wrap">
                            <li :class="{'active_tab': adminActions_tabs.banList}"
                                @click="changeFrameCustom('banList', adminActions_tabs)">Бан лист
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.ultimateBanHammer}" v-if="$root.isAdmin()"
                                @click="changeFrameCustom('ultimateBanHammer', adminActions_tabs)">Ульт. Бан хаммер
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.tasks}"
                                @click="changeFrameCustom('tasks', adminActions_tabs)">Задачи
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.financeOperations}" v-if="$root.isAdmin()"
                                @click="changeFrameCustom('financeOperations', adminActions_tabs)">Финансовые операции
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.statusManagement}"
                                @click="changeFrameCustom('staffManagement', adminActions_tabs)">Менеджмент статусов
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.serverManagement}"
                                @click="changeFrameCustom('serverManagement', adminActions_tabs)">Управление серверами
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.imitation}" v-if="$root.isAdmin()"
                                @click="changeFrameCustom('imitation', adminActions_tabs)">Имитация
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.core}"
                                @click="changeFrameCustom('core', adminActions_tabs)">CORE
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.sauronEye}"
                                @click="changeFrameCustom('sauronEye', adminActions_tabs)">Sauron eye (LOGS)
                            </li>
                            <li :class="{'active_tab': adminActions_tabs.godsEyes}"
                                @click="changeFrameCustom('godsEyes', adminActions_tabs)"> God's eyes
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="c-frame" style="min-width: 1100px;">

                    <div class="al-actions-frame" v-if="adminActions_tabs.banList">
                        <ban-list/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.banHammer">
                        <ban-hammer/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.tasks">
                        <tasks/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.financeOperations">
                        <finance-operations/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.statusManagement">
                        <status-management/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.serverManagement">
                        <server-management/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.imitation">
                        <imitation/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.core">
                        <core/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.sauronEye">
                        <sauron-eye/>
                    </div>

                    <div class="al-actions-frame" v-if="adminActions_tabs.godsEyes">
                        <gods-eyes/>
                    </div>


                </div>
            </div>
        </div>
        <div v-else>
            <NotFound></NotFound>
        </div>
    </div>
</template>

<script>

import {TextInputControlMixin} from "@/mixins/TextInputControlMixin";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";

import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";

import NotFound from "@/views/NotFound";
import banList from "@/components/elements/moder/banList";
import imitation from "@/components/elements/admin/imitation";
import core from "@/components/elements/admin/core";
import serverManagement from "@/components/elements/admin/serverManagement";


export default {
    name: "Admin",
    components: {NotFound, banList, imitation, core, serverManagement},
    mixins: [TextInputControlMixin, PreloaderMixin, TimeFormatterMixin, ChangeTabMixin],
    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Панель администратора',
    },

    data() {
        return {
            adminActions_tabs: {
                banList: true,
                ultimateBanHammer: false,
                tasks: false,
                activity: false,
                financeOperations: false,
                statusManagement: false,
                serverManagement: false,
                imitation: false,
                core: false,
                sauronEye: false,
                godsEyes: false
            }
        }
    },
    methods: {},
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.content {
    @include flexible();
    width: 100vw;

    .c {
        @include simple-ctrr();
        @include in-page-preloader();

        @include pretty-actions();

    }
}

@include ct-n-rp-transitions();
@include info-table-with-paginator();

.pulseNickName {
    margin-left: 5px;
    animation: 5s ease infinite alternate text-shadow-gain-pulse-more;
    transition: all 0.5s ease;
    -webkit-user-select: none;
}


</style>