<template>
    <div class="core_name_window">
        <div class="topUp_Text">Введите название модуля</div>
        <input type="text" class="m-input" @keyup="errored = false" style="margin-top: 10px"
               placeholder="Название модуля" v-model="coreName">
        <div class="btn" style="margin-top: 10px" v-if="coreName !== '' && !errored" @click="uploadCore">
            Загрузить
        </div>
        <div class="btn-error" style="margin-top: 10px" v-else-if="errored">Ошибка!</div>
        <div class="btn-inactive" style="margin-top: 10px" v-else>Загрузить</div>
    </div>
</template>

<script>

import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {ModalsCommonMixin} from "@/mixins/ModalsCommonMixin";

export default {
    name: "CoreNameModal",
    mixins: [PreloaderMixin, ModalsCommonMixin],
    props: ['data', 'url', 'field'],

    data() {
        return {
            coreName: '',
            errored: false,
        }
    },

    methods: {
        uploadCore() {
            this.preloaderStartModal('.core_name_window')
            let data = this.data
            data[this.field] = this.coreName
            require('axios').post(this.url, data).then(data => {
                this.$root.$emit('coreLoadSuccess', 'OK')
                this.preloaderStop('.core_name_window')
                this.closeWithDelay()
            }).catch(err => {
                this.preloaderStop('.core_name_window')
                this.errored = true
            })
        },
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

.core_name_window, btn, btn_inactive {
    @include flexible();
}


.core_name_window {
    flex-direction: column;
    padding: 20px 0px;
    border-radius: 7px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
    color: $not-bright-white;
}

.topUp_Text {
    font-size: 1em;
    background: linear-gradient(90deg, #fd1d1d 0, #fcb045 0, rgba(252, 0, 255, .8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-user-select: none;
    transition: all 0.5s ease;
    animation: 10s ease infinite alternate multicolored-iridescent-gradient;
}

.m-input {
    width: 40%;
    height: 28px;
    border-radius: 3px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;
    font-family: AventirN;
    font-size: 0.9em;
}

.btn, .btn-inactive, .btn-error {
    @include main-button(4px, 15px)
}

.btn:hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.02);
    color: white;
}

.btn-inactive {
    background-image: none;
    background-color: gray;
    cursor: default;
}

.btn-error {
    background-image: none;
    background-color: #d93737;
    cursor: default;
}

.btn-inactive:hover, .btn-error:hover {
    transform: none;
}

.core_name_window {
    @include flex-column();
    padding: 36px 70px;
    border-radius: 5px;
    background-color: $shitty-hex-gray;
    border: 1px solid rgba(128, 128, 128, 0.13);
    font-family: $main-font;
}

</style>