<template>
    <div class="serverManagement">
        <div class="apreloader" v-if="servers.length === 0"/>
        <div class="console" v-if="messages[selectedServer] !== undefined" ref="console"
             style="border: 1px dashed white;background: black; color: white; padding: 10px;height: 40em;overflow-y: scroll">
            <div v-for="message in messages[selectedServer]">
                <span :class="span.class" v-for="span in getFormattedMessage(message)">{{ span.text }}</span>
            </div>
        </div>
        <div v-if="servers.length !== 0" style="display: flex;padding: 5px">
            <CustomSelect position="top" style="margin: 2px;" v-bind:options="servers" v-bind:default="servers[0]"
                          @send="selectServer($event)"/>
            <input class="filter-input" type="text"
                   v-model="command" placeholder="Команда">
            <input class="filter-input" type="button" style="cursor: pointer"
                   @click="sendCommand()" value="Выполнить команду">
            <input class="filter-input" type="button"
                   :value="`Uptime: ${uptimeServer}`">
            <input class="filter-input2" type="button" :class="{'hidden': !autoScroll}" style="cursor: pointer"
                   @click="autoScroll = !autoScroll" :value="`Автопрокрутка ${autoScroll ? 'вкл.' : 'выкл.'}`">
        </div>
    </div>
</template>

<script>
    import CustomSelect from "@/components/elements/donat/CustomSelect";
    import {PreloaderMixin} from "@/mixins/PreloaderMixin";
    import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";

    export default {
        mixins: [PreloaderMixin, ChangeTabMixin],
        components: {CustomSelect},
        name: "serverManagement",
        computed: {
            uptimeServer: function () {
                return this.uptime[this.selectedServer] + this.updatedComponent(this.componentKey)
            }
        },
        data() {
            return {
                servers: [],
                work: {},
                messages: {},
                selectedServer: 'Dregora',
                componentKey: 0,
                uptime: {},
                uptimeTimers: {},
                command: '',
                wss: {},
                autoScroll: true,
            }
        },
        methods: {
            updatedComponent(key) {
                return (' ' + key).slice(0, 1)
            },
            getColor(text) {
                if (text.length === 6 || text.length === 7) {
                    if (text === '\x30\x3B\x33\x31\x3B\x31')
                        return 'red'
                    if (text === '\x30\x3B\x33\x32\x3B\x31')
                        return 'aqua'
                    if (text === '\x30\x3B\x33\x33\x3B\x31')
                        return 'yellow'
                    if (text === '\x30\x3B\x33\x34\x3B\x31')
                        return 'blue'
                    if (text === '\x30\x3B\x33\x35\x3B\x31')
                        return 'purple'
                    if (text === '\x30\x3B\x33\x36\x3B\x31')
                        return 'darkaqua'
                    if (text === '\x30\x3B\x33\x37\x3B\x31')
                        return 'white'
                    if (text === '\x30\x3B\x33\x38\x3B\x31')
                        return 'gold'
                    if (text === '\x30\x3B\x33\x39\x3B\x31')
                        return 'lightgreen'
                    if (text === '\x30\x3B\x33\x40\x3B\x31')
                        return 'green'
                    return 'yellow'
                }
                return ''
            },
            getFormattedMessage(message) {
                let parts = []
                message.split("\x1B\x5B").forEach(s => {
                    if (s.length > 0) {
                        if (s[0] === '\x6D') {
                            parts.push({
                                'class': 'log-white',
                                'text': s.slice(1)
                            })
                        } else {
                            let d = s.split('\x6D')
                            let color = this.getColor(d[0])
                            if (color !== '') {
                                if (d.length >= 2) {
                                    parts.push({
                                        'class': `log-${color}`,
                                        'text': d.slice(1).join('\x6D')
                                    })
                                }
                            } else {
                                parts.push({
                                    'class': 'log-white',
                                    'text': s
                                })
                            }
                        }
                    }
                })
                return parts
            },
            getAvailableServers() {
                require('axios').get('api/admin/console').then(
                    data => {
                        this.servers = data.data
                        this.servers.forEach(i => {
                            this.work[i] = false
                            this.uptime[i] = 'connecting...'
                            this.messages[i] = []
                            this.uptimeTimers[i] = null
                        })
                        this.preloaderStop('.apreloader')
                    }
                ).catch(err => {
                    console.log(err)
                })
            },
            sendCommand() {
                if (this.work[this.selectedServer]) {
                    this.wss[this.selectedServer].send(JSON.stringify({
                        type: 30,
                        data: this.command
                    }));
                    this.command = ''
                }
            },
            selectServer(server) {
                this.selectedServer = server
                if (this.work[server])
                    return
                this.uptimeTimers[server] = setTimeout(() => {
                    this.uptime[server] = 'not work';
                    this.componentKey += 1;
                }, 15000);
                require('axios').get(`api/admin/console/${server}`).then(
                    dt => {
                        let token = dt.data.token
                        let self = this
                        let ws = new WebSocket("wss://greatray.ru/rconsole/");

                        ws.onmessage = ({data}) => {
                            let date = new Date();

                            data = JSON.parse(data)

                            if (data.type === -1 && !this.work[server]) {
                                self.wss[server] = ws
                                self.work[server] = true
                            } else {
                                let message = "";

                                if (data.type === 0)
                                    message = "Server started";
                                if (data.type === 1)
                                    message = "Server stopped";
                                if (data.type === 2 || data.type === 3)
                                    message = data.payload.data;
                                if (data.type === 10) {
                                    if (self.uptimeTimers[server] != null)
                                        clearTimeout(self.uptimeTimers[server]);
                                    let s = Math.round(data.payload.uptime / 1000)
                                    self.uptime[server] = s + ' s';
                                    self.componentKey += 1;
                                    self.uptimeTimers[server] = setTimeout(() => {
                                        self.uptime[server] = 'not work';
                                        self.componentKey += 1;
                                    }, 15000);
                                }
                                if (message !== "") {
                                    self.messages[server] = self.messages[server].slice(Math.max(0, self.messages[server].length - 1000))
                                    self.messages[server].push(message)
                                    self.componentKey += 1;
                                    if (self.autoScroll)
                                        requestAnimationFrame(() => {
                                            self.$refs.console.scrollTop = self.$refs.console.scrollHeight + 1000;
                                        })
                                }
                            }
                        }

                        ws.onopen = function (e) {
                            console.log('open ' + server)
                            ws.send('@auth ' + token);
                        }

                        ws.onerror = function (e) {
                            console.log(e)
                            self.work[server] = false
                        }
                        ws.onclose = function (e) {
                            console.log(e)
                            self.work[server] = false
                        }
                    }
                ).catch(err => {
                    console.log(err)
                })
            }
        },
        mounted() {
            this.preloaderStartLk('.apreloader')
            this.getAvailableServers()
        }
    }
</script>

<style scoped lang="scss">
    @import "src/assets/scss/animations";
    @import "src/assets/scss/mixins";
    @import "src/assets/scss/vars";

    .filter-input, .filter-input2 {
        margin: 2px;
        background-color: #28272b;
        border-left: 5px solid #4f48b5;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
        border-radius: 6px;
        color: #fff;
        padding-left: 1em;
        padding-right: 1em;
        height: 38px;
        width: 168px;
    }

    .console {
        -webkit-user-select: text;
    }

    .filter-input {
        border-left: 5px solid #4f48b5;
    }

    .filter-input2 {
        border-left: 5px solid #48b54a;
    }

    .hidden {
        opacity: 0.2;
        border-left: 5px solid #b54848;
    }

    .log-white {
        color: white
    }

    .log-red {
        color: #d45a5a
    }

    .log-yellow {
        color: yellow;
    }

    .log-aqua {
        color: aqua;
    }

    .log-darkaqua {
        color: #318b8b;
    }

    .log-darkred {
        color: #ff0000;
    }

    .log-blue {
        color: blue;
    }

    .log-green {
        color: green;
    }

    .log-purple {
        color: purple;
    }

    .log-lightgreen {
        color: lightgreen;
    }

    .log-gold {
        color: gold;
    }
</style>