<template>
    <div class="imitation">
        <div class="i-list-wrappa" :key="il">
            <div class="block-title-wrp">
                <div class="block-title">Лист имитаций
                </div>
                <div class="b-t-check">
                    <input type="checkbox" id="yours" v-model="yours" @click="changeTypeOfLogging">
                    <label for="yours">Твои?</label>
                </div>
            </div>

            <div class="imitations-list" @scroll="onScroll">
                <div class="apreloader" style="width: 100%; height: 100%"/>
                <div class="items" v-if="imitationLogs">
                    <div class="imitation-item" v-for="pl in imitationLogs.data"
                         @click="selectPl(pl)">
                        <div class="i-left" :ref="`element${pl.id}`">
                            <div class="pl-name">pl: <span style="color: white">{{ JSON.parse(pl.data)['name'] }}</span>
                            </div>
                            <div class="pl-ip">ip: {{ pl.ip }}</div>
                        </div>
                        <div class="i-right">
                            <div class="pl-id">id: {{ pl.id }}</div>
                            <div class="item-created-at">{{ datetimeNormalizer(pl.created_at) }}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="imitate-info-field">
            <div class="block-title-wrp">
                <div class="block-title">Информация о имитации
                </div>
            </div>

            <div class="imitations-list">
                <div class="block-info overInfoList"
                     style="display:flex; flex-direction: column; width:100%; height: 100%; align-items: center; justify-content: center"
                     v-if="selectedImitation">
                    <div class="ii-id">id: {{ selectedImitation.id }}</div>
                    <div class="ii-id">action: {{ selectedImitation.action }}</div>
                    <div class="ii-id">name: {{ JSON.parse(selectedImitation.data)['name'] }}</div>
                    <div class="ii-id">ip: {{ selectedImitation.ip }}</div>
                    <div class="ii-id">imitator_id: {{ selectedImitation.player_id }}</div>
                    <div class="ii-id">created_at: {{ datetimeNormalizer(selectedImitation.created_at) }}</div>
                </div>
                <div class="block-info overInfoList" v-else-if="liveSearchList.length > 0">
                    <div v-for="name in liveSearchList" @click="toSimulatePlayerName = name; liveSearchList = [name]">
                        <span class="s-m-live-search-name">{{ name }}</span>
                    </div>
                </div>
                <div class="block-info overInfoList" v-else>
                    <div class="ii-id" style="width: 60%">
                        Тут отобразится информация о имитации при выборе в левом меню.
                        <br><br>
                        Также здесь отобразятся варианты имени игрока во время набора имени игрока в правом меню.
                    </div>
                </div>
            </div>

        </div>

        <div class="imitate-field">

            <div class="block-title-wrp">
                <div class="block-title">Имитация пользователя
                </div>
            </div>
            <input class="c-input" type="text" v-model="toSimulatePlayerName" @keyup="liveSearchPlayer(toSimulatePlayerName)">
            <div class="processImitate" @click="processImitate"> Стать кошкодевочкой!</div>
        </div>
    </div>
</template>

<script>
import {PreloaderMixin} from "@/mixins/PreloaderMixin";
import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import {PlayerLiveSearchMixin} from "@/mixins/PlayerLiveSearchMixin";

export default {
    name: "imitation",
    mixins: [PreloaderMixin, TimeFormatterMixin, PlayerLiveSearchMixin],

    data() {
        return {
            toSimulatePlayerName: '',
            imitationLogs: null,
            blockLoad: false,
            page: 1,
            selectedImitation: null,
            yours: false,
            il: 0
        }
    },

    methods: {
        onScroll() {
            let articleRef = this.$refs[`element${this.imitationLogs.data[this.imitationLogs.data.length - 1].id}`]
            if (articleRef) {
                let posArticleTop = articleRef[0].getBoundingClientRect().top
                let innerHeight = window.innerHeight
                if ((posArticleTop - innerHeight) < -30) {
                    if (this.imitationLogs !== null)
                        if (this.imitationLogs.last_page !== this.page) {
                            this.getImitationAllLogs(true)
                        }
                }
            }
        },

        changeTypeOfLogging() {
            this.yours = !this.yours
            this.page = 1
            this.imitationLogs = null
            this.getImitationAllLogs()
            this.il++;
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        },

        selectPl(d) {
            this.selectedImitation = d
            this.toSimulatePlayerName = JSON.parse(d.data)['name']
        },

        processImitate() {
            if (!this.toSimulatePlayerName)
                return

            const axios = require('axios');
            axios.post('/api/auth/imitate', {
                "name": this.toSimulatePlayerName
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {
                this.$root.redirect('/')
            }).catch(err => {
                console.log(err.response.status);
                console.log(err.response.data.errors)
            })
        },


        getImitationAllLogs(onskr = false) {
            if (this.blockLoad) {
                return
            }

            this.blockLoad = true

            let imitationAllLogsUrl = '/api/admin/operations'
            imitationAllLogsUrl += `?action=imitate_start&per_page=15&page=${this.page}`

            if (this.yours)
                imitationAllLogsUrl += `&player_id=${this.$root.getPlayerData().id}`


            if (!onskr)
                this.preloaderStartLk('.apreloader')

            require('axios').get(imitationAllLogsUrl).then(data => {
                if (!onskr) {
                    this.imitationLogs = data.data
                    this.last_page = data.last_page
                    this.preloaderStop('.apreloader')
                    this.blockLoad = false
                } else {
                    this.page += 1
                    data.data.data.forEach(i => {
                        this.imitationLogs.data.push(i)
                    })
                    this.blockLoad = false
                }
            })
        },
    },
    mounted() {
        this.preloaderStartLk('.apreloader')
        this.getImitationAllLogs()
    },
}


</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.s-m-live-search-name {
    border-radius: 10px;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    background-color: rgb(89, 51, 194);
    color: white;
    cursor: pointer;
}

.imitation {
    @include flexible-row-sp-bt();
    font-family: $main-font;
    @include in-page-preloader();
    align-items: flex-start;
}

.block-title-wrp {
    @include flexible-row-start();
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.imitations-list {
    @include flexible-column();
    overflow-y: auto;
    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 10px;

    width: 350px;
    height: 400px;
    border: 1px dashed $brightless-gray;

    .items {
        width: 100%;
    }

    .imitation-item {
        @include flexible-row-sp-bt();
        @include desctiption-0-8();
        width: 93%;
        padding: 5px;

        color: $dull-gray;

        &:hover {
            background: rgba(128, 128, 128, 0.12);
            cursor: pointer;
        }
    }
}

.block-title {
    display: flex;
    @include title-1-2();
    align-items: flex-start;
    justify-content: flex-start;
    color: $not-bright-white;
}

.b-t-check {
    @include desctiption-0-8();
    color: $brightless-white;
}

.imitate-field {
    @include flexible-column();
    align-items: flex-start;
    justify-content: flex-start;
}

.c-input {
    width: 100%;
    height: 28px;
    border-radius: 3px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;
    font-family: AventirN;
    font-size: 0.9em;
}

.ii-id {
    color: $brightless-white;

}

.overInfoList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.processImitate {
    @include flexible();
    width: 102%;
    height: 370px;
    color: red;
    font-size: 1.1em;
    transition: all 0.3s ease;
    cursor: pointer;

    border-radius: 5px;
    background: url(../../../assets/img/koshechka.jpg);
    background-size: cover;


    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
    }

    &:active {
        margin-top: 5px;
        transform: scale(1.04);
        transition: all 0.1s ease;
    }

}


</style>