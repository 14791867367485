<template>
    <div class="serverCore">
        <div class="apreloader" v-show="loader"/>
        <div v-show="!loader">

            <input class="filter-input2"
                   :class="{'hidden': action_disabled}"
                   type="button"
                   value="Обновить"
                   :disabled="action_disabled"
                   @click="action_disabled=true;getAvailableServers()"
                   style="cursor: pointer;width: auto"
            >
            <input class="filter-input" :class="{'hidden': server.disabled}" type="button" :value="server.server_name"
                   @click="toggle(server)"
                   style="cursor: pointer;width: auto" v-for="server in servers">
            <hr>
            <input class="filter-input" type="text"
                   v-model="core.search" placeholder="Модуль">
            <input class="filter-input" type="text"
                   v-model="core.path" placeholder="Путь обновления">
            <input class="filter-input" type="button" @click="action(core.path, enabled, 'update', core.name, true)"
                   value="Включить обновления" style="cursor: pointer">
            <br>
            <input class="filter-input2" type="button"
                   v-show="core.search !== ''"
                   :value="c.name"
                   :class="{'hidden': c.name !== core.name}"
                   @click="core.name = c.name;core.search=c.name"
                   style="cursor: pointer;width: auto"
                   v-for="c in cores.filter(s => s.name.includes(core.search)).slice(0, 6)">
            <hr>
            <div style="display: flex">
                <input class="filter-input" type="text"
                       v-model="filters.search" placeholder="Поиск в файлах" @keyup="searchFiles()">
                <CustomSelect style="margin: 4px;" v-bind:options="getOptions(states_values)" default="CORE"
                              position="top"
                              @send="changeParam('state', states_values, $event); componentKey++"/>
            </div>
            <hr>
        </div>
        <div v-show="!loader">
            <table class="core">
                <tr>
                    <td v-for="server in computedServers">
                        {{ server.server_name }}
                    </td>
                    <td>Путь</td>
                    <td>Действия</td>
                </tr>
                <tr v-for="param in computedParams">
                    <td v-for="server in computedServers" :class="{
                        'orange': checkState(param.data[server.server_name], ['a.not', 'unk', 'a.old']),
                        'violet': checkState(param.data[server.server_name], ['a.del']),
                        'black': checkState(param.data[server.server_name], ['m.not']),
                        'blue': checkState(param.data[server.server_name], ['d.del']),
                        'green': checkState(param.data[server.server_name], ['a.act']),
                        'red': checkState(param.data[server.server_name], ['m.old', 'm.act', 'error'])
                    }"> <!--v-tooltip="getTooltip(param.data[server.server_name])"-->
                        <div>{{
                                param.data[server.server_name] !== undefined ? param.data[server.server_name].state : '-'
                            }}
                        </div>
                        <div v-show="param.control_delete">
                            <div
                                class="delete_cancel"
                                @click="action(param.path, server.server_name, 'delete', '', false)"
                                v-if="checkState(param.data[server.server_name], ['a.del', 'd.del'])"
                            >
                                RST DEL
                            </div>
                            <div
                                class="delete"
                                @click="action(param.path, server.server_name, 'delete', '', true)"
                                v-else-if="param.data[server.server_name] !== undefined"
                            >
                                DEL
                            </div>
                        </div>
                        <div v-show="param.control_update">
                            <div
                                class="update_cancel"
                                @click="action(param.path, server.server_name, 'update', param.core_name, false)"
                                v-if="checkState(param.data[server.server_name], ['a.act', 'a.old', 'a.not'])"
                            >
                                RST UPD
                            </div>
                            <div
                                class="update"
                                @click="action(param.path, server.server_name,'update', param.core_name, true)"
                                v-if="checkState(param.data[server.server_name], ['m.act', 'm.old', 'm.not'])"
                            >
                                UPD
                            </div>
                        </div>
                        <div v-show="param.control_load">
                            <div
                                class="load"
                                @click="action(param.path, server.server_name,'load', param.core_name, true)"
                                v-if="checkState(param.data[server.server_name], ['a.old', 'm.old', 'unk', 'a.del'])"
                            >
                                LOAD
                            </div>
                        </div>
                    </td>
                    <td class="path">{{ param.path }}</td>
                    <td style="display: flex">
                        <div
                            class="delete"
                            @click="param.control_update=false;param.control_load=false;param.control_delete = !param.control_delete; componentKey++"
                        >DEL
                        </div>
                        <div
                            class="update"
                            @click="param.control_delete=false;param.control_load=false;param.control_update = !param.control_update; componentKey++"
                        >UPD
                        </div>
                        <div
                            class="load"
                            @click="param.control_delete=false;param.control_update=false;param.control_load = !param.control_load; componentKey++"
                        >LOAD
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import CustomSelect from "@/components/elements/donat/CustomSelect";
    import {PreloaderMixin} from "@/mixins/PreloaderMixin";
    import {ChangeTabMixin} from "@/mixins/ChangeTabMixin";
    import CoreNameModal from "@/components/modals/CoreNameModal";


    export default {
        mixins: [PreloaderMixin, ChangeTabMixin],
        components: {CustomSelect},
        name: "core",
        computed: {
            computedParams: function () {
                return this.params.filter(p => this.checkFilters(p) && this.updatedComponent(this.componentKey) === ' ')
            },
            computedServers: function () {
                return this.servers.filter(s => !s.disabled && this.updatedComponent(this.componentKey) === ' ')
            }
        },
        data() {
            return {
                servers: [],
                params: [],
                componentKey: 0,
                cores: [],
                filters: {
                    search: '',
                    state: '',
                },
                action_disabled: false,
                loader: false,
                core: {
                    path: '',
                    name: ''
                },
                states: ['error', 'unk', 'd.del', 'a.del', 'a.not', 'a.old', 'a.act', 'm.act', 'm.old', 'all'],
                states_values: {
                    'CORE': 'all-core',
                    'Не загружено/авто': 'a.not',
                    'Не обновлено/авто': 'a.old',
                    'Удалено/авто': 'd.del',
                    'Актуально/авто': 'a.act',
                    'Ожидает удаления': 'a.del',
                    'Не загружено/вручную': 'm.not',
                    'Не обновлено/вручную': 'm.old',
                    'Актуально/вручную': 'm.act',
                    'Ошибка': 'error',
                    'Неизвестно': 'unk',
                    'Всё': 'all',
                },
                enabled: ['HiTech', 'GalaxyTech', 'GTNH', 'GTNH-2022', 'Elysium', 'Asgard']
            }
        },
        methods: {
            updatedComponent(key) {
                return (' ' + key).slice(0, 1)
            },
            getTooltip(param) {
                if (param !== undefined) {
                    switch (param.state) {
                        case 'error':
                            return 'Критическая ошибка, нужно немедленно разобраться'
                        case 'unk':
                            return 'Модуль в системе CORE не обнаружен'
                        case 'd.del':
                            return 'Модуль был поставлен на удаление и удаление успешно завершено'
                        case 'a.del':
                            return 'Модуль был поставлен на удаление и ожидает удаления'
                        case 'a.not':
                            return 'Модуль на автообновлении и ожидает установки'
                        case 'a.act':
                            return 'Модуль на автообновлении и в актуальном состоянии'
                        case 'a.old':
                            return 'Модуль на автообновлении и ожидает обновления'
                        case 'm.act':
                            return 'Модуль в актуальном состоянии, но не будет обновляться'
                        case 'm.old':
                            return 'Модуль устарел, но не будет обновляться'
                        case 'm.not':
                            return 'Модуль отсутствует и не будет загружен'
                    }
                }
                return 'Модуля нет в системе CORE и на сервере'
            },
            checkFilters(param) {
                let show = this.filters.search === '' || param.path.includes(this.filters.search)
                if (!show)
                    return false
                let am = 0
                this.servers.filter(s => !s.disabled).forEach(s => {
                    if (param.data[s.server_name] !== undefined && (this.filters.state === 'all' || param.data[s.server_name].state === this.filters.state || (this.filters.state === 'all-core' && param.data[s.server_name].state !== 'unk')))
                        am++
                })
                return am > 0
            },
            getOptions(options) {
                let res = []
                for (var key in options)
                    res.push(key)
                return res
            },
            changeParam(key, mapping, res) {
                if (mapping !== null)
                    res = mapping[res]
                this.filters[key] = res
            },
            checkState(version, variants) {
                if (version === undefined)
                    return false
                let c = false
                variants.forEach(s => {
                    if (s === version.state)
                        c = true
                })
                return c
            },
            action(path, server_name, action, name, work) {
                //this.loader = true
                //this.preloaderStartLk('.apreloader')
                if (this.action_disabled)
                    return
                this.action_disabled = true
                let data = {
                    action: action,
                    path: path,
                    server: server_name,
                    work: work,
                    name: name
                }
                if(action === "load"){
                    this.$modal.show(
                        CoreNameModal,
                        {
                            url: 'api/admin/core',
                            field: 'name',
                            data: data,
                        },
                        {
                            styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none; ",
                            width: "420px",
                            height: "auto",
                            reset: true,
                        }
                    )
                }else{
                    require('axios').post('api/admin/core', data).then(
                        data => {
                            this.getAvailableServers();
                        }
                    ).catch(err => {
                        console.log(err)
                    })
                }
            },
            getAvailableServers() {
                require('axios').get('api/admin/core').then(
                    data => {
                        this.servers = data.data.servers
                        this.servers.forEach(s => {
                            s.disabled = !this.enabled.includes(s.server_name)
                        })
                        let control_update = this.params.filter(s => s.control_update).map(s => s.path)
                        let control_delete = this.params.filter(s => s.control_delete).map(s => s.path)
                        let control_load = this.params.filter(s => s.control_load).map(s => s.path)
                        this.params = data.data.params
                        this.params.forEach(s => {
                            s.control_update = control_update.includes(s.path)
                            s.control_delete = control_delete.includes(s.path)
                            s.control_load = control_load.includes(s.path)
                        })
                        this.cores = data.data.core
                        this.componentKey++
                        if (this.loader) {
                            this.loader = false
                            this.preloaderStop('.apreloader')
                        }
                        this.action_disabled = false
                    }
                ).catch(err => {
                    console.log(err)
                })
            },
            searchFiles() {
                this.componentKey++;
            },
            toggle(server) {
                server.disabled = !server.disabled
                this.enabled = []
                this.servers.forEach(s => {
                    if (!s.disabled)
                        this.enabled.push(s.server_name)
                })
                this.componentKey++;
            }
        },
        mounted() {
            this.loader = true
            this.preloaderStartLk('.apreloader')
            this.getAvailableServers()
            let self = this
            this.$root.$on('coreLoadSuccess', function (arg) {
                self.getAvailableServers();
            })
        }
    }
</script>

<style scoped lang="scss">
    @import "src/assets/scss/tooltips";
    @import "src/assets/scss/animations";
    @import "src/assets/scss/mixins";
    @import "src/assets/scss/vars";

    .core tr td {
        color: white;
        border: 1px groove gray;
        margin: 2px;
        padding: 2px;
        text-align: center;
        vertical-align: center;
        font-size: 0.9em;
    }

    .core {
        background: #1c1c1d;
    }

    .filter-input, .filter-input2 {
        margin: 4px;
        background-color: #28272b;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
        border-radius: 6px;
        color: #fff;
        padding-left: 1em;
        padding-right: 1em;
        height: 40px;
        width: 168px;
    }

    .filter-input {
        border-left: 5px solid #4f48b5;
    }

    .filter-input2 {
        border-left: 5px solid #48b54a;
    }

    .hidden {
        opacity: 0.2;
        border-left: 5px solid #b54848;
    }

    .red {
        background: #af1010;
    }

    .orange {
        background: #af7a10;
    }

    .green {
        background: #10af3d;
    }

    .blue {
        background: #10afaf;
    }

    .violet {
        background: #8510af;
    }

    .black {
        background: #000000;
    }

    .path {
        -webkit-user-select: text;
    }

    .delete, .update, .delete_cancel, .update_cancel, .load {
        width: auto;
        height: auto;
        padding: 4.5px 3px;
        white-space: nowrap;
        margin: 2px;
        border-radius: 5px;
        cursor: pointer;
        font-family: AventirN;
    }

    .delete {
        background-image: linear-gradient(to top, #903b3b, #c93939, #b92c2c), linear-gradient(to top, #391313, #5f36cf);
    }

    .update {
        background-image: linear-gradient(to top, #41903b, #47c939, #62b92c), linear-gradient(to top, #223913, #5f36cf);
    }

    .load {
        background-image: linear-gradient(to top, #8a3b90, #b339c9, #b02cb9), linear-gradient(to top, #351339, #5f36cf);
    }

    .delete_cancel {
        background-image: linear-gradient(to top, #3b5d90, #39c9a7, #2c5bb9), linear-gradient(to top, #131e39, #5f36cf);
    }

    .update_cancel {
        background-image: linear-gradient(to top, #907b3b, #c9a039, #b9912c), linear-gradient(to top, #393013, #5f36cf);
    }

    .serverCore {
        @include flexible-column();
        @include fill-all-block();

        @include in-page-preloader();

        @include info-table-with-paginator();


        .pretty-border {
            @include smooth-border();
            margin: 0;
        }

        .flexible-block {
            width: 100%;
            @include pretty-actions();
        }
    }

</style>